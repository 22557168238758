<template>
  <div class="my-main-menu">
    <div style="position: sticky; top: 0; left: 0; z-index: 10">
      <div class="header">
        <el-avatar
          class="men-face"
          :src="agentInfo.AgentFace ? agentInfo.AgentFace : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.AgentName }}</span>
          <span class="item">ID: {{ agentInfo.AgentCode }}</span>
          <span class="item">
            {{ PAGELANG.invitationcode }}: {{ agentInfo.InviteCode }}
          </span>
        </div>
      </div>
      <ul class="menu">
        <li class="item" :class="pagename == 'profile' ? 'active' : ''">
          <a href="#/profile">
            <span>
              <i class="iconfont icon-ziliao-01" />
              {{ PAGELANG.个人资料 }}
            </span>
          </a>
        </li>
        <li class="item" :class="pagename == 'agentgroup' ? 'active' : ''">
          <a href="#/agentgroup">
            <span>
              <i class="iconfont icon-tuandui2" />
              {{ PAGELANG.我的团队 }}
            </span>
          </a>
        </li>
        <li class="item" :class="pagename == 'order' ? 'active' : ''">
          <a href="#/order">
            <span>
              <i class="iconfont icon-order" />
              {{ PAGELANG.order }}
            </span>
          </a>
        </li>
        <li class="item" :class="pagename == 'address' ? 'active' : ''">
          <a href="#/address">
            <span>
              <i class="iconfont icon-dizhi-02" />
              {{ PAGELANG.dizhi }}
            </span>
          </a>
        </li>
        <li class="item" :class="pagename == 'credit' ? 'active' : ''">
          <a href="#/credit">
            <span>
              <i class="iconfont icon-licai" />
              {{ PAGELANG.daikuanzhanghu }}
            </span>
          </a>
        </li>
        <li class="item" :class="pagename == 'income' ? 'active' : ''">
          <a href="#/income">
            <span>
              <i class="iconfont icon-shouyi" />
              {{ PAGELANG.shouyizhanghu }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
export default {
  name: "MyMenu",
  props: {
    agentInfo: {
      type: Object,
      default: null,
    },
    pagename: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultFace: require("@/assets/images/default_face.png"),
    };
  },
  beforeCreate() {
    const root = getCurrentInstance().appContext.config.globalProperties;
    this.SYSLANG = root.LANG.system;
    this.PAGELANG = root.LANG.pages.my;
    this.root = root;
  },
};
</script>

<style scoped>
@media (min-width: 679px) {
  .my-main-menu {
    width: calc(100vw / 1920 * 280);
    margin-right: calc(100vw / 1920 * 28);
    background-color: #fff;
    box-sizing: border-box;
  }
  .my-main-menu .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vw / 1920 * 230);
    border-bottom: 1px solid #f7f7f7;
  }
  .my-main-menu .header >>> .men-face {
    --el-avatar-size: calc(100vw / 1920 * 98);
    background-color: #f7f7f7;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
  }
  .my-main-menu .header .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: calc(100vw / 1920 * 22);
    font-size: calc(100vw / 1920 * 14);
    color: #000;
  }
  .my-main-menu .header .info .name {
    line-height: calc(100vw / 1920 * 34);
    font-size: calc(100vw / 1920 * 18);
    font-weight: bold;
  }
  .my-main-menu .menu {
    padding: calc(100vw / 1920 * 30) calc(100vw / 1920 * 25);
  }
  .my-main-menu .menu .item a {
    display: flex;
    width: 100%;
    height: 42px;
    margin: 4px 0;
    box-sizing: border-box;
    padding: 0 calc(100vw / 1920 * 16);
    align-items: center;
    justify-content: space-between;
    font-size: calc(100vw / 1920 * 16);
    color: #000;
    transition: all 0.3s ease-in-out;
  }
  .my-main-menu .menu .item a:hover {
    color: var(--van-card-price-color);
  }
  .my-main-menu .menu .item a::after {
    content: "\e68b";
    font-family: "iconfont";
    font-size: calc(100vw / 1920 * 12);
  }
  .my-main-menu .menu .item.active a {
    background-color: #000;
    color: #fff;
  }
}
</style>